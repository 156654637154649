import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Brand } from 'app/components/Icons'
import { theme } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  className?: string
  variant?: Variant
}

export const IconSeparator = memo(function IconSeparator({
  className,
  variant = 'default',
}: Props) {
  return (
    <Container className={className} variant={variant}>
      <Brand />
    </Container>
  )
})

const Container = styled.div<ContainerProps>`
  text-align: center;

  svg {
    width: auto;
    height: 5rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
    margin: auto;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'dark':
        return css`
          svg {
            fill: ${theme.colors.variants.primaryDark2};
          }
        `
    }
  }}
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'dark'
