import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  direction?: 'L' | 'R'
  onClick?: (e: any) => void
}

export const Arrow = memo(function Arrow({ direction = 'L', onClick }: Props) {
  return (
    <Container dial={5} onClick={onClick}>
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.variants.primaryDark4};
    }
  }

  svg {
    width: auto;
    height: 1.5rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
    transition: 0.2s ease-in-out;
  }
`
